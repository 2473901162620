import HomePage from './Pages/HomePage/HomePage';
const routes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        component: HomePage
    }
];

export default routes;
